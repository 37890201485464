@use "animations";
@use "styles";

.Card {
	--cta-vertical-padding: 18px;

	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	box-sizing: border-box;
	padding-left: env(safe-area-inset-left);
	padding-right: env(safe-area-inset-right);
	background: var(--card-background);
	transform: translate3d(0, 0, 0);

	> .contents {
		flex-shrink: 1;
		flex-grow: 1;
		overflow-x: hidden;
		--padding-h: 20px;
		min-height: 30px;
		padding: 30px 0 20px;

		> h1:first-child {
			padding-top: 20px;
		}

		> .banner {
			position: relative;
			flex-shrink: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100vw;
			height: 130px;
			margin-bottom: 20px;
			overflow: hidden;

			> .background {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background: hsla(0, 0%, 0%, 0.15);
				border-radius: inherit;

				> .SmartImage {
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					border-radius: inherit;
				}
			}

			> .container {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				padding: 15px 20px;

				> h1 {
					font-size: 34px;
					padding: 0 30px;
					color: white;
					text-align: center;
					text-shadow:
						0 0.1em 0.3em hsla(0, 0%, 10%, 0.7),
						0 0 0.6em hsla(0, 0%, 10%, 0.7);
					transform: translate3d(0, 0, 0);
				}
			}
		}

		> .LogoTitle {
			width: 100vw;
			padding-top: 25px;
			padding-bottom: 15px;
			box-sizing: border-box;
			
			> .SmartImage {
				width: inherit;
				height: inherit;
				max-height: inherit;
				border-radius: 12px;
			}
		}

		> .auto-columns {
			display: flex;
			flex-direction: column;
	
			> .Body {
				padding-left: var(--padding-h);
				padding-right: var(--padding-h);
				box-sizing: border-box;
			}
	
			> h1 + .Body {
				margin-top: 1em;
			}
		}
	
		> .Body {
			padding-left: var(--padding-h);
			padding-right: var(--padding-h);
			box-sizing: border-box;
		}
	
		.Body p + p {
			margin-top: 0.5em;
		}

		> h1 + .Body,
		.LogoTitle + .Body {
			margin-top: 1em;
		}

		.slides-wrapper {
			flex-shrink: 0;
			position: relative;
		}

		img.centered {
			display: block;
			border-radius: 8px;
			max-width: 100%;
			max-height: 40vh;
			margin: 0 auto;

			&:not(:first-child) {
				margin-top: 20px;
			}
		}

		h1 {
			padding-left: var(--padding-h);
			padding-right: var(--padding-h);
			font-size: 28px;
			font-weight: 600;
	
			&:not(:first-child) {
				margin-top: 40px;
			}
		}
	}

	&.home > .contents {
		padding-top: 0;
	}
	
	ul.items-list {
		list-style: none;
		padding: 0;
		margin: 15px 0 0;

		> li {
			list-style: none;
			padding: 0;
			margin: 0;
			
			&:not(:first-child) {
				border-top: var(--soft-border);
			}
		}
	}

	dl.definition-list {
		margin: 15px 0 0;
		padding: 0;

		.DefinitionItem {
			border-top: var(--border);

			&:last-child {
				border-bottom: var(--border);
			}
		}
	}

	> .cta {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		flex-shrink: 0;
		flex-direction: column;
		align-items: center;
		padding: var(--cta-vertical-padding) 32px calc(max(var(--cta-vertical-padding), env(safe-area-inset-bottom)));
		background: radial-gradient(ellipse at 50% 75%,
			var(--card-background) 38%,
			var(--card-background-translucent) 50%,
			var(--card-background-transparent) 73%
		);
		background-size: 200% 100%;
		background-position: center center;
		animation: fade-in 1s animations.$ease-out-cubic 0.2s backwards;

		> .Action {
			width: 100%;
			touch-action: none;
			display: block;
			padding: 0.6em 10px;
			font-size: 17px;
			border-radius: 0.6em;
			text-align: center;
			background: var(--action-surface);
			animation: actions-slide-up 0.7s animations.$ease-snappy 1.2s backwards;

			& + .Action {
				margin-top: 5px;
			}

			$i: 0;
			@while $i < 10 {
				&:nth-child(#{$i + 1}) {
					animation-delay: 0.2s + $i * 0.1s;
				}
				$i: $i + 1;
			}

			&:only-child {
				font-size: 18px;
			}

			@keyframes actions-slide-up {
				from {
					opacity: 0;
					transform: scale(0.9) translateY(7em);
				}
				to {
					opacity: 1;
					transform: scale(1) translateY(0);
				}
			}
		}
	}

	&.with-cta > .contents::after {
		content: "";
		flex-shrink: 0;
		display: block;
		width: 100%;
		height: calc(var(--cta-vertical-padding) * 2 + var(--cta-count) * 45px);
	}

	> .center-overlay {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&.with-listing {		
		> .contents {
			> ul.items-list {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				flex-grow: 1;
			}
		}
	}
}

.App.color-scheme-light .cta > .Action {
	@include styles.floaty-button-light;
}

.App.color-scheme-dark .cta > .Action {
	@include styles.floaty-button-dark;
}

.Card > iframe {
	padding-top: 50px;
	height: calc(100% - 50px);
	width: 100%;
	overflow: hidden;
	background: transparent;
}

.QRCodeCard {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	> .title {
		text-align: center;
		font-size: 20px;
	}

	> .qr-code {
		margin: 30px 0;
	}

	> .description {
		font-size: 16px;
		line-height: 1.5em;
	}
}

.ImagesCard {
	> .contents {
		display: flex;
		flex-direction: column;

		.Slides.multiple > .slides-container {
			padding-bottom: 42px;
		}

		.Slides .slides-container {
			height: calc(var(--vh, 1vh) * 100 - 160px);
		}
	}
}

.App.small-ui .Card {
	&:not(.home) > .contents > .banner {
		margin-top: 15px;
	}
	
	&.ItemsListCard,
	&.CardsListCard,
	&.PricingListCard {
		> .contents > ul.items-list {
			margin-top: 20px;
		}
	}
	
	.slides-wrapper {
		padding-top: 8px;
	}

	> .contents {
		> .LogoTitle {
			width: calc(100vw - var(--padding-h) * 2);
			padding-left: var(--padding-h);
			padding-right: var(--padding-h);
		}

		> .auto-columns > .Body:first-child {
			margin-top: 15px;
		}
	}

	
}

.App.large-ui .Card {
	> .contents {
		> .LogoTitle {
			width: 100vw;
			max-height: 250px;
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}

	&.with-cta > .contents::after {
		height: calc(var(--cta-vertical-padding) * 2 + var(--cta-count) * 60px);
	}
}

.App.large-ui .ImagesCard {
	h1 {
		margin-bottom: 15px;
	}

	.Slides .slides-container {
		width: 100vw;
		height: calc(var(--vh, 1vh) * 100 - 230px);
	}
}

.App.large-ui .Card {
	--cta-vertical-padding: 7vh;
	
	> .contents {
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow-y: auto;
	
		> h1 {
			font-size: 30px;
			max-width: 70vw;
		}
	
		> .auto-columns {
			display: flex;
			flex-direction: row;
	
			.slides-wrapper {
				padding-top: 25px;
				width: 440px;
			}

			> .Body:not(:first-child) {
				margin-top: 25px;
				margin-left: 25px;
			}
		}
	
		> .Body,
		> .auto-columns > .Body {
			padding: 25px;
			line-height: 1.75em;
		}
	
		> .Body {
			max-width: var(--max-width);
			padding-left: 0;
			padding-right: 0;
		}

		> .auto-columns > .Body {
			max-width: 500px;
		}
	}

	> .cta {
		> .Action {
			max-width: 400px;
			font-size: 20px;

			&:only-child {
				font-size: 22px;
			}

			& + .Action {
				margin-top: 15px;
			}
		}
	}

	dl.definition-list {
		margin: 15px 0 0;
		padding: 0;

		.DefinitionItem {
			border: var(--border);
			border-radius: 16px;

			.icon {
				font-size: 34px;
			}

			.name {
				font-size: 18px;
			}

			.value {
				font-size: 18px;
			}

			&:focus,
			&:active {
				border-color: var(--color-selected);
			}

			&:not(:first-child) {
				margin-top: 10px;
			}
		}
	}
	
	&.with-max-width {
		--max-width: 600px;

		> .contents {
			// Any side effects?
			// flex-shrink: 0;

			> .banner {
				height: 200px;
				
				> .container {
					width: 80vw;
					max-width: var(--max-width);

					> h1 {
						font-size: 40px;
					}
				}
			}

			> .LogoTitle {
				width: var(--max-width);
			}

			> ul.items-list {
				margin-top: 3vh;
				align-items: center;
	
				.ListItem {
					width: 80vw;
					max-width: var(--max-width);
				}
			}

			> dl.definition-list {
				margin-top: 3vh;
				align-items: center;
	
				.DefinitionItem {
					width: 80vw;
					max-width: var(--max-width);
				}
			}

			@media (min-width: 820px) {
				> .banner {
					width: 800px;
					margin-left: auto;
					margin-right: auto;
					margin-top: 30px;
					border-radius: 16px;
				}
			}
		}
	}
}