@use "styles";

.DefinitionItem {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	flex-direction: row;
	height: var(--height);
	--border-radius: 10px;
	padding: 15px 20px;

	&.with-action {
		background: var(--card-background-soft-contrast);

		&:hover,
		&:focus {
			background: var(--card-background-soft-contrast-hover);
			outline: none;
		}
		&:active {
			background: var(--card-background-soft-contrast-active);
		}
	}

	> .icon {
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 30px;
		width: 1.4em;
		height: 1em;
		margin-right: 0.6em;
	}

	> .text {
		overflow-x: hidden;

		> dt.name {
			flex-grow: 2;
			margin: 0;
			padding: 0;
			color: var(--color);
			font-weight: 600;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			word-wrap: break-word;
		}
	
		> dd.value {
			flex-grow: 0;
			margin: 0.3em 0 0 0;
			padding: 0;
			color: var(--color-half-soft);
			overflow-x: hidden;
			text-overflow: ellipsis;
		}
	}
}