@use "general.sass";
@use "website_general.sass";
@use "animations.sass";

body.website .Hero {
	overflow: hidden;
	-webkit-font-smoothing: antialiased;
	animation: blur-in 5s animations.$ease-out-cubic 0.2s backwards;
	min-width: inherit;
	--box-shadow:
		-6px -12px 22px -3px hsla(310, 100%, 97%, 0.2),
		6px 12px 22px -3px hsla(219, 100%, 12%, 0.4);
		
	.logo {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		
		> img.stacky {
			width: 320px;
		}
		
		> .sub-logo-title {
			margin-top: -10px;
			color: hsla(0, 0%, 100%, 0.9);
			font-size: 60px;
			font-weight: 600;
			text-align: center;
			letter-spacing: -0.01em;
			line-height: 1em;
			text-shadow: var(--text-shadow-impact);
		}

		@media #{website_general.$small-ui} {
			margin-top: 30px;

			> img.stacky {
				width: 280px;
			}
		
			> .sub-logo-title {
				font-size: 26px;
			}
		}
	}

	> .columns {
		height: 440px;
		margin-top: 10vh;
		margin-bottom: 10vh;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;

		@media #{website_general.$small-ui} {
			margin-top: 50px;
			margin-bottom: 40px;
		}

		> .column {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: inherit;
			
			&:first-child {
				flex-shrink: 0;
				margin-right: 40px;

				@media #{website_general.$small-ui} {
					margin-right: 0;
					margin-bottom: 20px;
				}
			}
		}

		@media #{website_general.$small-ui} {
			width: 100vw;
			height: auto;
			flex-direction: column;
			align-items: center;
		}
	}
	
	.Slides.cards {
		--width: 260px;
		--height: 446px;
		width: var(--width);
		height: var(--height);
		perspective: 2000;
		-webkit-perspective: 2000;

		.image {
			display: block;
			width: var(--width);
			height: var(--height);
			border-radius: 16px;
			background-image: url("images/cards@2x.jpg");
			background-size: cover;
			background-position: left center;
			box-shadow: var(--box-shadow);
			color: transparent;

			&:hover {
				transform: scale(1.03);
				transition-duration: 0.3s;
			}

			&:active {
				transform: scale(0.99);
				transition-duration: 0.1s;
			}
		}

		.slide {
			&:nth-child(2) .image {
				background-position: calc(var(--width) * -1) center;
			}

			&:nth-child(3) .image {
				background-position: calc(var(--width) * -2) center;
			}

			&:nth-child(4) .image {
				background-position: calc(var(--width) * -3) center;
			}
		}
	}

	.Slides.titles {
		@include general.solidify;
		cursor: pointer;
		width: 340px;
		height: 1em;
		padding: 4px 18px 9px;
		background: hsla(0, 0%, 100%, 0.09);
		border-radius: 16px;
		transition: background cubic-bezier(0.215, 0.610, 0.355, 1) 0.2s;
		font-size: 40px;
		text-shadow: var(--text-shadow);
		border: 2px solid hsla(0, 0%, 100%, 0.1);
		animation: pulsing-titles 3s animations.$ease-in-out-cubic infinite both 1.5s;

		&.wider {
			width: 400px;
		}

		&:hover {
			background: hsla(0, 0%, 100%, 0.14);
			border-color: hsla(0, 0%, 100%, 0.2);
			animation: none;
		}

		&:active {
			background: hsla(0, 0%, 60%, 0.14);
			border-color: hsla(0, 0%, 100%, 0.4);
			animation: none;
		}

		@keyframes pulsing-titles {
			20% {
				border-color: hsla(0, 0%, 100%, 0.55);
			}
		}

		.text {
			width: 600px;
			height: 1em;
			text-transform: uppercase;

			&.tight {
				font-size: 38px;
				letter-spacing: -0.04em;
			}
		}

		.slide {
			--z-depth: 21px;
		}
	}

	.Slides.qr {
		width: var(--size);
		height: var(--size);

		.image {
			width: var(--size-with-border);
			height: var(--size-with-border);
			background-image: url("images/qr-codes.png");
			background-size: cover;
			background-position: left center;
			border: 5px solid white;
			border-radius: 10px;
			box-shadow: var(--box-shadow);
			box-sizing: border-box;
		}

		.slide {
			&:nth-child(2) .image {
				background-position: calc(var(--size) * -1) center;
			}

			&:nth-child(3) .image {
				background-position: calc(var(--size) * -2) center;
			}

			&:nth-child(4) .image {
				background-position: calc(var(--size) * -3) center;
			}
		}
	}

	.Slides.labels {
		width: 350px;
		height: 30px;

		.slide {
			width: inherit;
			--z-depth: 12px;
		}
	}

	.title-pre-after {
		font-size: 26px;
		font-weight: 500;
		margin: 5px 0 5px 18px;
		color: hsla(0, 0%, 100%, 0.6);
		text-shadow: var(--text-shadow);
	}

	.copy {
		@media #{website_general.$small-ui} {
			transform: scale(0.75);
		}
		
		@media #{website_general.$ultra-thin-ui} {
			transform: scale(0.7);
		}
	}

	.scan-qr {
		--size: 90px;
		--size-with-border: calc(var(--size) + 10px);
		height: var(--size-with-border);
		margin-top: 20px;
		margin-left: 20px;
		display: flex;
		flex-direction: row;

		@media #{website_general.$small-ui} {
			display: none;
		}
		
		.qr-code {
			position: relative;
			width: var(--size-with-border);
			height: var(--size-with-border);

			.corner {
				--corner-size: 80px;
				--corner-offset: calc(var(--corner-size) * -0.4);
				position: absolute;
				z-index: 1;
				width: var(--corner-size);
				height: var(--corner-size);
				background: url("images/qr-camera-corner@2x.png");
				background-size: contain;
				transition: opacity 1.5s animations.$ease-out-cubic 0.5s;
				animation: corner-margin 2.5s animations.$ease-in-out-cubic infinite 0.5s;

				@keyframes corner-margin {
					from, 90%, to {
						margin: 0;
					}
					35% {
						margin: -5px;
					}
				}

				&.top-left {
					top: var(--corner-offset);
					left: var(--corner-offset);
				}

				&.top-right {
					top: var(--corner-offset);
					right: var(--corner-offset);
					transform: rotate(90deg);
				}

				&.bottom-right {
					bottom: var(--corner-offset);
					right: var(--corner-offset);
					transform: rotate(180deg);
				}

				&.bottom-left {
					bottom: var(--corner-offset);
					left: var(--corner-offset);
					transform: rotate(270deg);
				}
			}

			&.transitioning {
				.corner {
					opacity: 0;
					transition-duration: 0.3s;
					transition-delay: 0s;
				}
			}
		}

		.legend {
			display: flex;
			max-width: 240px;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			margin-left: 30px;
			text-shadow: var(--text-shadow);

			.soft {
				font-size: 18px;
				font-weight: 500;
				opacity: 0.6;
			}

			.strong {
				font-size: 26px;
				font-weight: 500;
				opacity: 0.8;
				line-height: 1em;
			}
		}

	}
	
	.click-hint {
		margin-top: 20px;
		color: hsla(0, 0%, 100%, 0.7);
		font-size: 13px;
		text-align: center;
	}
}