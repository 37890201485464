@use "animations";

.Slides {
	position: relative;

	> .slides-container {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		width: 100%;
		height: 100%;
		padding: 10px 0 30px; // Used to prevent shadow clipping.
		scroll-snap-type: x mandatory;
		overflow-x: auto;
		overflow-y: hidden;
		will-change: scroll-position;
		scroll-behavior: smooth;

		&::-webkit-scrollbar,
		&::-webkit-scrollbar-thumb:horizontal { 
			display: none; 
			height: 0;
			visibility: hidden;
		}

		> .slide {
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			flex-grow: 1;
			flex-shrink: 0;
			scroll-snap-align: start;
			padding-left: 20px;
			padding-right: 20px;

			> .SmartImage {
				width: 100%;
				height: 100%;
				border-radius: calc(min(2.5vw, 16px));
				box-sizing: border-box;
			}	
		}
	}

	&.multiple > .slides-container {
		padding-bottom: 36px;

		> .slide {
			> .SmartImage {
				@media (prefers-color-scheme: light) {
					box-shadow:
						0 10px 30px -15px rgba(50, 50, 73, 0.5),
						0 15px 20px -25px rgba(0, 0, 0, 0.6);
				}

				@media (prefers-color-scheme: dark) {
					box-shadow:
						0 5px 30px -10px rgba(238, 238, 238, 0.5),
						0 15px 20px -25px rgba(243, 243, 243, 0.6);
				}
			}	
		}
	}

	&.auto-ratio > .slides-container {
		> .slide {
			padding: 0;

			> .SmartImage {
				border-radius: none;
				box-shadow: none;
				
				> .image {
					object-fit: contain;
				}
			}
		}
	}

	> .controls {
		position: absolute;
		right: 0;
		left: 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		> button {
			padding: 5px 7px;
			color: var(--color);
			font-size: 15px;
			border-radius: 5px;
			transition: 0.3s animations.$ease-snappy;
			transition-property: opacity, background;

			&:enabled {
				cursor: pointer;
				opacity: 0.5;
				&:hover {
					
					opacity: 1;
				}
				&:active {
					opacity: 1;
				}
			}

			&:disabled {
				opacity: 0.3;
			}
		}

		> .indicator {
			padding: 6px;
			border-radius: 10px;

			&:enabled {
				opacity: 1;
			}

			&::before {
				content: "";
				display: block;
				width: 7px;
				height: 7px;
				background: var(--color);
				border-radius: 100%;
				opacity: 0.3;
			}

			&:hover::before {
				opacity: 0.5;
			}

			&:active::before,
			&.active::before {
				opacity: 1;
			}
		}
	}
}

.App.small-ui {
	.Slides .controls {
		bottom: 5px;

		button.directional {
			visibility: hidden;
		}
	}
}

.App.large-ui {
	.Slides .controls {
		bottom: 5px;

		> button.directional {
			visibility: visible;
		}

		> button:enabled {
			&:hover {
				background: var(--soft-button-hover);
			}
			&:active {
				background: var(--soft-button-active);
			}
		}
	}
}