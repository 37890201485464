.Body {
	p {
		line-height: 1.5em;
	}

	ul, ol {
		margin: 0;
		padding: 0;
		line-height: 1.4em;

		li:not(:first-child) {
			margin-top: 0.2em;
		}
	}

	p, ul, ol {
		font-size: inherit;
	}

	ul:not(:first-child),
	ol:not(:first-child) {
		margin-top: 0.5em;
	}

	h2 {
		margin-bottom: 0.6em;

		&:not(:first-child) {
			margin-top: 1.3em;
		}
	}

	h3 {
		margin-bottom: 0.5em;

		&:not(:first-child) {
			margin-top: 1.3em;
		}
	}

	p:not(:first-child) {
		margin-top: 0.5em;
	}

	blockquote {
		margin-left: 1em;
		padding: 0.3em 0 0.3em 1em;
		border-left: 2px solid var(--color-soft-decoration);
	}
}

.App.small-ui .Body {
	font-size: 17px;

	ul {
		padding-left: 30px;
	}

	ol {
		padding-left: 30px;
	}

	h2 {
		font-size: 26px;
	}

	h3 {
		font-size: 22px;
	}
}

.App.large-ui .Body {
	font-size: 19px;

	ul {
		padding-left: 30px;
	}

	ol {
		padding-left: 30px;
	}

	h2 {
		font-size: 30px;
	}

	h3 {
		font-size: 24px;
	}
}