@use "animations"

@keyframes fade-in
	0%
		opacity: 0
	100%
		opacity: 1

@keyframes fade-out
	0%
		opacity: 1
	100%
		opacity: 0



.slide-left-enter
	transform: translate(50%)
	filter: opacity(0)

	&.slide-left-enter-active
		pointer-events: none
		transform: translate(0)
		filter: opacity(1)
		transition: all var(--card-transition-duration, 0.5s) animations.$ease-out-quint

.slide-left-exit
	transform: scale(1)
	filter: opacity(1)
	transform-origin: 20% center

	&.slide-left-exit-active
		pointer-events: none
		transform: scale(0.9)
		filter: opacity(0)
		transition: all var(--card-transition-duration, 0.5s) animations.$ease-out-quint