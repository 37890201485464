body {
	width: 100vw;
	height: var(--vh, 1vh);
	margin: 0;
	padding: 0;
	overflow: hidden;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica", "Roboto", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

body.color-scheme-dark {
	background: black;
}

body.color-scheme-light {
	background: white;
}

h1, h2, h3, h4, h5, h6, p {
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;
}