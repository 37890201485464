/* Commons */

.App {
	--color-alert-danger: #db0e11;
}


/* Light color scheme */

.App.color-scheme-light {
	--color: hsl(0, 0%, 10%);
	--color-half-soft: hsl(0, 0%, 35%);
	--color-soft: hsl(0, 0%, 55%);
	--color-soft-decoration: hsl(0, 0%, 82%);
	--color-selected: hsl(210, 100%, 50%);
	--card-background: hsl(0, 0%, 98%);
	--card-background-translucent: hsla(0, 0%, 98%, 80%);
	--card-background-transparent: hsla(0, 0%, 98%, 0%);
	--card-background-soft-contrast: hsl(0, 0%, 100%);
	--card-background-soft-contrast-hover: hsl(0, 0%, 95%);
	--card-background-soft-contrast-active: hsl(0, 0%, 90%);
	--action-background: hsl(0, 0%, 100%);
	--soft-border: 1px solid hsl(0, 0%, 89%);
	--border: 1px solid hsl(0, 0%, 86%);
	--card-behind-filters: brightness(0.9) opacity(0.2);
	--card-front-filters: brightness(1.6);
	--missing-object-color: hsl(0, 0%, 92%);
	--bottom-shade: hsl(195, 3%, 90%);
	--soft-button-ultrasoft: hsla(0, 0%, 10%, 0.09);
	--soft-button-hover: hsla(0, 0%, 10%, 0.15);
	--soft-button-active: hsla(0, 0%, 10%, 0.25);
}


/* Dark color scheme */

.App.color-scheme-dark {
	--color: hsl(0, 0%, 95%);
	--color-half-soft: hsl(0, 0%, 60%);
	--color-soft: hsl(0, 0%, 45%);
	--color-soft-decoration: hsl(0, 0%, 20%);
	--color-selected: hsl(209, 100%, 50%);
	--card-background: hsl(0, 0%, 0%);
	--card-background-translucent: hsla(0, 0%, 0%, 80%);
	--card-background-transparent: hsla(0, 0%, 0%, 0%);
	--card-background-soft-contrast: hsl(0, 0%, 9%);
	--card-background-soft-contrast-hover: hsl(0, 0%, 12%);
	--card-background-soft-contrast-active: hsl(0, 0%, 14%);
	--action-background: hsl(0, 0%, 10%);
	--soft-border: 1px solid hsl(0, 0%, 16%);
	--border: 1px solid hsl(0, 0%, 20%);
	--card-behind-filters: brightness(0.5) opacity(0.2);
	--card-front-filters: brightness(0.4);
	--missing-object-color: hsl(0, 0%, 10%);
	--bottom-shade: hsl(220, 15%, 0%);
	--soft-button-ultrasoft: hsla(0, 0%, 95%, 0.09);
	--soft-button-hover: hsla(0, 0%, 95%, 0.15);
	--soft-button-active: hsla(0, 0%, 95%, 0.25);
}