@use "general";
@use "animations";

.StackViewer {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100%;
	box-sizing: border-box;
	overflow: hidden;

	> .navigation {
		@include general.solidify;
		pointer-events: none;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		display: flex;
		padding: 15px 20px 12px;
		flex-shrink: 0;
		z-index: 100;
		opacity: 0;
		background-color: var(--card-background);
		transition: opacity 0.3s animations.$ease-snappy;

		@supports ((-webkit-backdrop-filter:blur(0)) or (backdrop-filter:blur(0))) {
			background-color: var(--card-background-translucent);
			backdrop-filter: saturate(180%) blur(20px);
			-webkit-backdrop-filter: saturate(180%) blur(20px);
		}
		
		> .go-back {
			display: block;
			cursor: pointer;
			color: var(--color-selected);

			> .icon {
				position: relative;
				left: -0.2em;
			}
		}
	}

	&.with-top-navigation {
		.navigation {
			pointer-events: auto;
			opacity: 1;
		}
	}

	> .viewport {
		position: relative;
		width: 100vw;
		height: 100%;
		box-sizing: border-box;
		overflow: hidden;
		flex-grow: 1;
		transform: translate3d(0, 0, 0);
	}

	.card-forward-enter {
		z-index: 1;
		transform: translateX(100vw);
		filter: value(--card-front-filters);
		
		&.card-forward-enter-active {
			filter: brightness(1);
			transform: translateX(0);
			transition: var(--card-transition-duration) animations.$ease-out-quint;
			transition-property: transform, filter;
		}
	}

	.card-forward-exit {
		z-index: 0;
		transform: scale(1);
		filter: brightness(1) opacity(1);

		&.card-forward-exit-active {
			transform: scale(var(--CARD_SENT_TO_BACKGROUND_SCALE));
			filter: var(--card-behind-filters);
			transition: var(--card-transition-duration) animations.$ease-snappy;
			transition-property: transform, filter;
		}
	}

	.card-backward-enter {
		z-index: 0;
		transform: scale(var(--CARD_SENT_TO_BACKGROUND_SCALE));
		filter: var(--card-behind-filters);
		
		&.card-backward-enter-active {
			transform: scale(1);
			filter: brightness(1) opacity(1);
			transition: var(--card-transition-duration) animations.$ease-snappy;
			transition-property: transform, filter;
		}
	}

	.card-backward-exit {
		z-index: 1;
		transform: translateX(0);
		filter: brightness(1);

		&.card-backward-exit-active {
			transform: translateX(100vw);
			filter: var(--card-front-filters);
			transition: var(--card-transition-duration) animations.$ease-out-quint;
			transition-property: transform, filter;
		}
	}
}

.StackViewer.large-ui {
	> .navigation {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding: 25px;
		font-size: 22px;

		> .go-back {
			width: 800px;
			max-width: 80vw;
		}

		> .go-back > .icon {
			left: 0;
			margin-right: 0.4em;
		}
	}

	.Card:not(.home) > .contents {
		padding-top: 60px;
	}
}

.full-screen-notice {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	> .title,
	> .go-stacky {
		animation: fade-in 1s animations.$ease-out-quint 0.5s backwards;
	}
	
	> .title {
		font-size: 28px;
		color: var(--color-half-soft);
	}
	
	> .go-stacky {
		margin-top: 1em;
		font-size: 19px;
		color: var(--color-selected);
		animation-delay: 1s;
	}

	.arrow-link {
		&::after {
			content: "\e905";
			font-family: "stacky";
			display: inline-block;
			margin-left: 0.3em;
			font-size: 0.85em;
		}
	}
}