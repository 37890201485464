@use "styles";

.ListItem {
	flex-shrink: 0;
	height: var(--height);
	display: flex;
	flex-direction: row;
	--border-radius: 10px;

	&:not(.with-action) {
		> .icon {
			display: none;
		}
	}

	&.with-action {
		> .icon {
			flex-shrink: 0;
			align-self: center;
			color: var(--color-soft);
			font-size: 0.9em;
		}

		&:hover,
		&:focus {
			background: var(--soft-button-ultrasoft);
			outline: none;
		}
		&:active {
			background: var(--soft-button-hover);
		}
	}

	.SmartImage {
		flex-shrink: 0;
		flex-grow: 0;
		width: var(--height);
		height: var(--height);
		margin-right: var(--margin);

		&.loading {
			background-color: var(--missing-object-color);
		}
	}

	.body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-grow: 1;

		> .title {
			color: var(--color);
		}

		> .description {
			color: var(--color-soft);
		}

		> .price {
			color: var(--color);
		}
	}
}



/*** Small UI ***/

.App.small-ui .ListItem {
	--height: 90px;
	--border-radius: 0px;
	font-size: 16px;
	padding: 8px 20px;

	.SmartImage {
		--margin: 6px;
		border-radius: 6px;

		.LoadingIndicator {
			font-size: 26px;
		}
	}

	&:not(.stretchy) {
		&.compact {
			--height: 36px;
		}

		.body {
			flex-shrink: 1;
			width: 1%;

			&:not(:first-child) {
				margin-left: 10px;
			}
	
			> .title {
				padding-top: 1px;
				overflow: hidden;
				font-size: 18px;
				font-weight: 500;
				text-overflow: ellipsis;
				white-space: nowrap;
				word-wrap: break-word;
			}
				
			&.only-title {
				justify-content: center;

				> .title {
					white-space: normal;
					line-height: 1.4em;
				}
			}
	
			> .description {
				width: 100%;
				margin-top: 1px;
				margin-bottom: 1px;
				max-height: 4em;
				overflow-y: hidden;
				line-height: 1.4em;
			}
		}
		
		> .icon {
			padding-left: 10px;
		}
	}

	&.stretchy {
		height: auto;
		padding: 15px 20px;

		.body {
			justify-content: flex-start;
			margin: 0;
			line-height: 1.4em;

			> .title {
				font-size: 18px;
				font-weight: 500;
			}

			> .description,
			> .price {
				margin-top: 0.5em;
			}
		}
		
		.SmartImage {
			margin: 0 0 0 20px;
			width: 25vw;
			height: 25vw;
			min-width: 80px;
			min-height: 80px;
			max-width: 150px;
			max-height: 150px;
		}
	}
}



/*** Large UI ***/

.App.large-ui .ListItem {
	--height: 130px;
	display: flex;
	padding: 12px 20px;
	flex-direction: row;
	font-size: 28px;

	.SmartImage {
		--margin: 0px;
		border-radius: var(--border-radius);
	}

	.body {
		padding-right: 20px;

		&:not(:first-child) {
			margin-left: 30px;
		}

		> .title {
			font-weight: 500;
		}

		> .description {
			font-size: 18px;
			line-height: 1.4em;
			margin-top: 6px;
		}
	}

	&.stretchy {
		height: auto;

		.body {
			margin: 0;

			> .title {
				font-size: 20px;
				font-weight: 500;
			}

			> .description,
			> .price {
				margin-top: 0.5em;
				font-size: 18px;
				line-height: 1.4em;
			}
		}
		
		.SmartImage {
			margin: 0 0 0 20px;
			width: 25vw;
			height: 25vw;
			min-width: 80px;
			min-height: 80px;
			max-width: 150px;
			max-height: 150px;
		}
	}
}