@use "website_general.sass";
@use "general.sass";

.Website {
	height: 350vh;
	width: 100vw;
	color: white;
	overflow: hidden;

	--text-shadow:
		-2px -4px 6px hsla(310, 100%, 97%, 0.15),
		4px 8px 11px hsla(219, 100%, 12%, 0.35);
	--text-shadow-impact:
		-3px -5px 9px hsla(310, 100%, 97%, 0.2),
		5px 7px 14px hsla(279, 100%, 12%, 0.35);

	@media #{website_general.$small-ui} {
		height: auto;
	}

	> section {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100vw;
		min-width: inherit;
		height: 100vh;

		@media #{website_general.$small-ui} {
			height: auto;
		}
	}

	> section.creator {
		overflow-x: hidden;
		height: 150vh;

		@media #{website_general.$small-ui} {
			height: auto;
			padding-top: 150px;
		}

		> .screenshot {
			display: flex;

			@media #{website_general.$small-ui} {
				width: 100vw;
				flex-direction: column;
			}
			
			> .image {
				width: 601px;
				background-size: contain;
				background-position: center right;
				background-repeat: no-repeat;

				@media #{website_general.$small-ui} {
					width: 100vw;
					height: 400px;
					background-size: contain;
					background-position: center center;
				}
			}
			
			&.mobile > .image {
				height: 533px;
				background-image: url("images/creator-mobile@2x.png");
			}
			
			&.desktop > .image {
				height: 528px;
				background-image: url("images/creator-desktop@2x.png");

				@media #{website_general.$small-ui} {
					height: 100vw;
				}
			}
			
			> .text {
				display: flex;
				padding: 20px 0 20px 20px;
				flex-direction: column;
				justify-content: space-around;
				width: 300px;

				@media #{website_general.$small-ui} {
					padding-top: 0px;
				}
			}
		}

		p {
			font-size: 30px;
			font-weight: 500;
			color: hsla(0, 0%, 100%, 0.8);
			text-shadow: var(--text-shadow);

			@media #{website_general.$small-ui} {
				font-size: 28px;
				margin: 0.5em 0;
			}
		}

		p.centered {
			margin-top: 60px;
			margin-left: 20px;
			margin-right: 20px;
		}

		> .link-qr-code {
			width: 274px;
			height: 108px;
			background-image: url("images/link-qr-code@2x.png");
			background-size: contain;
		}
	}

	> section.large-text {
		height: 100vh;

		> .container {
			text-align: left;
			width: 600px;
			box-sizing: border-box;

			@media #{website_general.$small-ui} {
				width: 100vw;
				padding: 0 20px;
			}
		}

		.pretitle {
			font-size: 20px;
			font-weight: 600;
			margin-bottom: 10px;
			color: hsla(0, 0%, 100%, 0.85);
			text-transform: uppercase;

			@media #{website_general.$small-ui} {
				font-size: 18px;
				margin-bottom: 4px;
			}
		}

		h1 {
			margin: 0;
			padding: 0;
			font-size: 56px;
			font-weight: 600;
			line-height: 1.2em;

			@media #{website_general.$small-ui} {
				font-size: 30px;
			}
		}

		p {
			width: 450px;
			margin: 25px 0;
			max-width: 100vw;
			padding: 0 20px 0 0;
			box-sizing: border-box;
			color: hsla(0, 0%, 100%, 0.75);
			font-size: 24px;
			font-weight: 500;
			line-height: 1.4em;

			@media #{website_general.$small-ui} {
				font-size: 20px;
				padding-right: 40px;
			}
		}

		a.orange {
			font-size: 22px;
			color: #FFAA2F;
			text-decoration: none;

			@media #{website_general.$small-ui} {
				font-size: 20px;
			}
		}
	}
}

body.website {
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 350vh;
	overflow-x: hidden;
	overflow-y: auto;
	background: linear-gradient(175deg,
		#ffd343,
		#EA2779 7.13%,
		#1A3572 25.38%,
		#001e34 33%,

		#0f1222 35%,
		#14163d 40%,
		#6b1993 47%,
		#B330C7 50%,
		#821c8a 55%,
		#272456,
		#010D17 67%,

		#010a11,
		#010f19,
		#325976 97%,
		#4988b7) !important;
	background-size: 100vw 390vh !important;
	background-position: center -20vh !important;
	font-family: "Helvetica Neue", "Roboto", "Helvetica", -apple-system, BlinkMacSystemFont, "Segoe UI", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

body.website .condensed {
	font-family: "Roboto Condensed", "Helvetica Neue", "Helvetica", "Roboto";
	font-stretch: condensed;
}

@media #{website_general.$small-ui} {
	body.website {
		height: auto !important;
		background-size: 100vw 110% !important;
	}
}