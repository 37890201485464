.App {
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: calc(var(--vh, 1vh) * 100);
	overflow: hidden;

	a {
		color: var(--color-selected);
	}

	button {
		margin: 0;
		padding: 0;
		border: none;
		background: transparent;
		outline: none;
	}
}

* {
	touch-action: pan-x pan-y;
}

.App.color-scheme-light {
	background: var(--card-background);
	color: hsl(0, 0%, 5%);
}

.App.color-scheme-dark {
	background: var(--card-background);
	color: hsl(0, 0%, 97%);
}

.DebugOverlay {
	position: fixed;
	top: 5px;
	right: 5px;
	z-index: 10000;
	padding: 15px;
	background: var(--card-background);
	opacity: 0.5;
	border-radius: 6px;

	> .row {
		display: flex;
		flex-direction: row;

		> label {
			margin-right: 10px;
		}
	}
}