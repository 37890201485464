@use "animations";

body.website .Slides {
	position: relative;
	perspective: 1200;
	-webkit-perspective: 1200;

	.scaler {
		width: inherit;
		height: 100%;
		transform-style: preserve-3d;
	}

	.slides-container {
		width: inherit;
		height: 100%;
		transform-style: preserve-3d;
		transform-origin: center center;
		transition: transform 1.2s cubic-bezier(0.215, 0.610, 0.355, 1);

		> .slide {
			position: absolute;
			backface-visibility: hidden;
			left: 0;
			top: 0;
			transition: filter 1s cubic-bezier(0.215, 0.610, 0.355, 1);
			perspective: 500;
			-webkit-perspective: 500;

			> .text,
			> .image {
				transition: transform 1s cubic-bezier(0.215, 0.610, 0.355, 1);
			}

			&:not(.active) {
				filter: brightness(0.8) opacity(0.2);

				> .image {
					transform: scale(0.9);
				}
			}
	
			&.text {
				left: 0;
				top: 0;
			}
	
			&.image {
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
			}
		}
	}

	&.fades {
		.slides-container {
			transform-style: unset;
			transform-origin: center center;
			transition: none;
	
			> .slide {
				transition: filter 1s cubic-bezier(0.215, 0.610, 0.355, 1);
	
				> .text,
				> .image {
					transition: transform 1s cubic-bezier(0.215, 0.610, 0.355, 1);
				}
	
				&:not(.active) {
					filter: opacity(0) blur(5px);
				}

				&.active {
					transition-delay: 0.1s;
				}
			}
		}
	}

	&.flips {
		transition: filter 1s cubic-bezier(0.215, 0.610, 0.355, 1);

		.slides-container {
			transform-style: unset;
			transform-origin: center center;
			transition: none;
	
			> .slide {
				transition: 1s cubic-bezier(0.215, 0.610, 0.355, 1);
				transition-property: filter, transform;
	
				&:not(.active) {
					filter: opacity(0);
					transform: rotateY(180deg);
				}

				&.active {
					transform: rotateY(0);
					transition-delay: 0.1s;
				}

				&.became-inactive {
					transform: rotateY(-180deg);
				}
			}
		}
	}
	
	&.text {
		perspective: 2000;
		-webkit-perspective: 2000;
	}

	&.text .slide {
		&:nth-child(1) {
			transform: rotateX(0) translateZ(var(--z-depth));
		}
		&:nth-child(2) {
			transform: rotateX(270deg) translateZ(var(--z-depth));
		}
		&:nth-child(3) {
			transform: rotateX(180deg) translateZ(var(--z-depth));
		}
		&:nth-child(4) {
			transform: rotateX(90deg) translateZ(var(--z-depth));
		}
	}
}