@use "website_general.sass";

body.website .sign-up-log-in {
	text-align: center;

	.sun-lamp-button {
		display: inline-block;
		padding: 8px 28px 9px;
		margin-top: 30px;
		margin-bottom: 2px;
		text-decoration: none;
		border-radius: 14px;
		background: linear-gradient(to top, #FF2400, #FFA51F 75%);
		color: #790F00;
		font-size: 20px;
		font-weight: 600;
		text-align: center;
		text-shadow: 0 1px 1px hsla(40, 100%, 95%, 0.5);
		box-shadow:
			inset 0 0 15px -5px #FFA51F,
			0 -15px 40px -10px hsla(29, 100%, 79%, 0.25),
			0 15px 50px -10px hsla(358, 100%, 47%, 0.7);
		
		@media #{website_general.$ultra-thin-ui} {
			padding-left: 15px;
			padding-right: 15px;
		}

		&:hover {
			background: linear-gradient(to top, #ff4800, #FFA51F 75%);
			box-shadow:
				inset 0 0 15px -5px #FFA51F,
				0 -15px 40px -10px hsla(29, 100%, 79%, 0.3),
				0 15px 50px -10px hsla(14, 100%, 57%, 0.7);
		}

		&:active {
			background: linear-gradient(to top, #FF2400, #ff841f 75%);
			box-shadow:
				inset 0 0 15px -5px #FFA51F,
				0 -15px 40px -10px hsla(29, 100%, 79%, 0.25),
				0 15px 50px -10px hsla(358, 100%, 47%, 0.7);
		}
	}

	> .pricing {
		margin-top: 7px;
		color: hsla(0, 0%, 100%, 0.7);
		font-size: 13px;
	}
}