@use "general";

.Action {
	@include general.solidify;
	display: block;
	-webkit-tap-highlight-color: transparent;

	&.disabled {
		pointer-events: none;
		color: var(--color-soft);
	}
}