@use "animations";

.LoadingIndicator {
	position: relative;
	width: 1em;
	height: 1em;
	font-size: 40px;
	opacity: 0;
	transition: opacity 0.4s animations.$ease-snappy 0.1s;

	&.visible {
		opacity: 1;
	}

	> .wheel,
	> .tick {
		position: absolute;
		width: inherit;
		height: inherit;

		> svg {
			width: inherit;
			height: inherit;
			transform-origin: center center;

			path {
				stroke: var(--color);
				stroke-width: 9px;
				fill: none;
			}
		}
	}
	
	> .wheel {
		opacity: 0.3;
	}

	> .tick {
		animation: loading-tick 2.5s steps(23) infinite;

		&::before {
			display: block;
			width: 1em;
			height: 1em;
		}

		&:nth-child(3) {
			opacity: 0.2;

			> svg {
				transform: rotate(-15deg);
			}
		}

		&:nth-child(4) {
			opacity: 0.1;

			> svg {
				transform: rotate(-30deg);
			}
		}
	}
}

@keyframes loading-tick {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(345deg);
	}
}