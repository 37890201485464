@use "general";
@use "animations";

.SmartImage {
	@include general.solidify;
	display: block;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: color(--card-background);
	transform: translate3d(0, 0, 0);
	transition: background-color 0.4s animations.$ease-out-quint;
	overflow: hidden;

	> .LoadingIndicator {
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -0.5em;
		margin-top: -0.5em;
	}

	> .image {
		display: block;
		filter: opacity(1);
		transition: filter 0.5s animations.$ease-snappy;
		border-radius: inherit;
	}

	&.contain > .image {
		max-width: 100%;
		max-height: 100%;
	}

	&.cover > .image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&.loading {
		> .image {
			filter: opacity(0);
		}
	}
}
