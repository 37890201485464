@mixin floaty-button-light {
	background: linear-gradient(to top, hsl(209, 53%, 93%), hsl(216, 15%, 97%) 30%, hsl(0, 0%, 100%) 80%);
	box-shadow:
		inset 0 0 0.3em 0.05em rgb(255, 255, 255),
		0 0 0.4em -0.3em hsla(221, 30%, 50%, 1),
		0 0.7em 1em -1em hsla(226, 50%, 50%, 0.8);

	&:hover,
	&:focus {
		outline: none;
		background: linear-gradient(to top, hsl(209, 53%, 95%), hsl(216, 15%, 98%) 30%, hsl(0, 0%, 100%) 80%);
	}

	&:active {
		color: hsl(214, 100%, 40%);
		background: linear-gradient(to top, hsl(216, 32%, 99%), hsl(216, 30%, 97%) 30%, hsl(209, 63%, 96%) 80%);
	}
}

@mixin floaty-button-dark {
	background: linear-gradient(to top, hsl(220, 3%, 16%), hsl(220, 2%, 16%) 30%, hsl(220, 3%, 18%));
	box-shadow:
		inset 0 1px 0 0 hsla(0, 0%, 100%, 0.09),
		inset 0 0 0.4em -0.3em hsl(48, 100%, 99%),
		0 0.7em 1em -1em hsla(226, 89%, 48%, 1);

	&:hover,
	&:focus {
		outline: none;
		background: linear-gradient(to top, hsl(220, 3%, 19%), hsl(220, 2%, 17%) 30%, hsl(220, 3%, 19%));
	}

	&:active {
		color: hsl(209, 100%, 58%);
		background: linear-gradient(to top, hsl(220, 3%, 13%), hsl(220, 2%, 13%) 30%, hsl(220, 3%, 12%));
	}
}